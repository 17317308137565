import 'react-app-polyfill/ie11'
import 'array-flat-polyfill'
import 'react-app-polyfill/stable'
import 'core-js/es/promise'
import 'core-js/es/symbol'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Zoom } from '@mui/material'
import { IntlProvider } from 'react-intl'
// import { ThemeProvider } from 'styled-components/macro'
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import nlMessages from 'devextreme/localization/messages/nl.json'
import { locale, loadMessages } from 'devextreme/localization'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import moment from 'moment'
import 'moment/locale/nl'
// import './whydidyourender';
import Root from './containers/Root'
// import registerServiceWorker from './registerServiceWorker';
// import { updateServiceWorker } from './actions/serviceWorkerActions';
import store from './store'
import locales from './locales'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import './assets/fonts.css'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/nl'
import theme from './components/layout/theme'
import { api } from './utilities/apiUtilities'
import { logout } from './actions/authenticationActions'
import { ACTION_EXPIRED } from './constants/auth'
import GlobalStyle from './components/layout/GlobalStyle'

const muiTheme = createTheme({
  ...theme,
  ...createTheme({}),
  palette: {
    primary: {
      main: theme.colors.secondary,
    },
    secondary: {
      main: theme.colors.primary,
    },
  },
  text: {
    primary: '#000000',
    secondary: '#000000',
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    button: {
      fontFamily: 'ROsanswebtext, system-ui, sans-serif',
      fontWeight: 700,
    },
    body2: {
      fontSize: '1rem',
    },
    fontFamily: 'ROsanswebtext, system-ui, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontSize: 15,
  },
})

// Disable react devtools in production
if (process.env.NODE_ENV === 'production' && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
  Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__).forEach(([key, value]) => {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null
  })
}

// Visit https://sentry.io for error analytics
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || 'develop',
    release: process.env.REACT_APP_GIT_SHA || 'develop',
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel
        }
      }
      return breadcrumb
    },
    beforeSend(event) {
      // Check if it is an exception, if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    },
  })
}

// TODO: Move this to an api specific place. Issue we're currently having is that
// the `store` import tries to load jsx during test-runs, which results in errors.
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || (error.response.status === 403 && error.response.data.code === 'secu-2')) {
        store.dispatch(logout(ACTION_EXPIRED))
        return Promise.reject(error)
      }

      if (error.response && error.response.status === 404) {
        const endpoint = (error.response.config || {}).url
        error.message = `Request failed with status code 404 on ${endpoint}`
      }

      if (
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
      ) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result)
            reject(error)
          }

          reader.onerror = () => {
            reject(error)
          }

          reader.readAsText(error.response.data)
        })
      }
    }

    return Promise.reject(error)
  }
)

moment.locale('nl')
initializeIcons()
loadMessages(nlMessages)
locale('nl-NL')

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <IntlProvider defaultLocale="nl" locale="nl" messages={locales.nl}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Zoom}
        >
          <GlobalStyle />
          <Root />
        </SnackbarProvider>
      </IntlProvider>
    </ThemeProvider>
  </Provider>
)

// registerServiceWorker(() => {
//   store.dispatch(updateServiceWorker());
// });
